<template>
  <div style="margin-top: 2rem;">
    <Main style="margin-top: 2rem;">
        <ValidationEvents></ValidationEvents>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { defineAsyncComponent } from 'vue';
//import { useRouter } from "vue-router";

const ValidationEvents = defineAsyncComponent(() => import('./overview/index'));

export default {
  name: 'ValidationEventsView',
  components: {
    Main,
    ValidationEvents
  },
  async setup() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "Validación de eventos");

    return {
    };
  },
};
</script>
<style scoped>
</style>
